<!--
 * @Description: 魔方多维布局配置
 * @Autor: WangYuan
 * @Date: 2021-06-03 13:59:00
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-29 10:48:06
-->
<template>
	<div class="wrap">
		<div class="wrap-label">
			<span>选择样式</span>
		</div>
		<div class="wrap-body">
			<div class="type-list">
				<el-tooltip effect="light" :content="item.name" placement="bottom" v-for="(item, index) in list"
					:key="index">
					<div class="flex-center pointer info-tab"
						:class="[value.model == item.value ? 'mode-otherTab-item-active' : 'grayscale']"
						@click="value.model = item.value">
						<svg-icon :icon-class="item.value" :class="value.model != item.value ? '' : 'bg-blue'"
							style="width:88px;height:64px;"></svg-icon>
						<svg-icon icon-class="active" v-if="value.model == item.value"
							style="width:16px;height:16px;position:absolute;right:0px;bottom:0px;z-index:99"></svg-icon>
					</div>
				</el-tooltip>
			</div>
		</div>
		<div v-if="value.model == 'figure4' || value.model == 'figure5'">
			<div class="wrap-label">
				<span>单行数量</span>
			</div>
			<div class="wrap-body">
				<div>
					<el-select v-model="value.max" placeholder="请选择">
						<el-option v-for="item in numArr" :key="item.id || index" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
	name: "SchemaType",

	mixins: [schemaMixin],

	components: {},

	props: {
		value: {
			type: Object,
			default: () => { },
		},

	},

	data () {
		return {
			list: [
				{ value: "figure1", name: '默认滚动' },
				{ value: "figure2", name: '圆形头像列表' },
				{ value: "figure3", name: '方形头像列表' },
				{ value: "figure4", name: '圆形头像卡片' },
				{ value: "figure5", name: '方形头像卡片' },
			],
			numArr: [
				{
					id: "1634893027qWrg",
					label: "1行2个",
					value: 2,
				},
				{
					id: "16348930081xgk",
					label: "1行3个",
					value: 3,
				},
			],
		};
	},

	methods: {},
};
</script>
<style lang="scss" scoped>
.mode-otherTab-item-active {
	position: relative;
}

.type-list {
	display: flex;
	flex-wrap: wrap;
}

.grayscale {
	filter: grayscale(1)
}

.pointer {
	cursor: pointer;
}

.info-tab {
	width: 88px;
	height: 64px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.mode-otherTab-item-active::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 87px;
	height: 63px;
	border: 1px solid #197aff;
	z-index: 9999;
	border-radius: 2px;
}

.wrap {
	background: #fff;

	.wrap-label {
		margin-left: -8px;
		font-size: 14px;
		/*no*/
		color: #323233;

		span {
			&::before {
				content: "*";
				color: red;
				padding-right: 2px;
			}
		}
	}

	.wrap-body {
		padding: 20px 10px;
		/*no*/

		.wrap-line {
			margin-top: 30px;
			height: 1px;
			background: #ebedf0;
		}
	}
}
</style>
